@import "bootstrap/scss/bootstrap.scss";
@import "react-bootstrap-sidebar-menu/dist/sidebar-menu.scss";


.main-wrapper {
  width: 100%;  
  height: 100%;
  background: #f3f4f6;
  grid-area: main;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "sidebar header" "sidebar main";
}

#App, .main-wrapper, body, html {
  height: 100vh;
  width: 100%;
}

.sidebar-menu {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}